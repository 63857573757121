<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <!-- brand logo -->
            <router-link to="/" class="brand-logo d-flex align-center">
                <v-img :src="appLogo" max-height="200px" max-width="200px" alt="logo" contain class="me-3"></v-img>

                <h2 class="text--primary">
                    {{ appName }}
                </h2>
            </router-link>
            <!--/ brand logo -->

            <v-row class="auth-row ma-0">
                <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
                    <div class="auth-illustrator-wrapper">
                        <!-- triangle bg -->
                        <!--  <img
                            height="362"
                            class="auth-mask-bg"
                            :src="
                                require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)
                            " /> -->

                        <!-- 3d character -->
                        <div class="d-flex align-center h-full pa-16 pe-0">
                            <v-img
                                contain
                                max-width="100%"
                                height="692"
                                class="auth-3d-group"
                                :src="require('@/assets/images/login/loginAltice.jpg')"></v-img>
                        </div>
                    </div>
                </v-col>

                <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
                    <v-row>
                        <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
                            <v-card flat>
                                <v-card-text>
                                    <p class="text-2xl font-weight-semibold text--primary mb-2">
                                        {{ $t('Login.Welcome') }} 👋🏻
                                    </p>
                                    <!--                                     <p class="mb-2">Please sign-in to your account and start the adventure</p>
 -->
                                </v-card-text>

                                <!-- login form -->
                                <v-card-text>
                                    <v-form @submit.prevent>
                                        <v-text-field
                                            v-model="form.identifier"
                                            outlined
                                            :label="$t('Email')"
                                            :placeholder="$t('Email')"
                                            hide-details="auto"
                                            class="mb-6"></v-text-field>

                                        <v-text-field
                                            v-model="form.password"
                                            outlined
                                            :type="isPasswordVisible ? 'text' : 'password'"
                                            :label="$t('Login.Password')"
                                            :placeholder="$t('Login.Password')"
                                            :append-icon="
                                                isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
                                            "
                                            hide-details="auto"
                                            class="mb-2"
                                            @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

                                        <!-- <div class="d-flex justify-end flex-wrap">
                                            <a class="ms-3" href="#"> {{ $t('Login.Forgot') }} </a>
                                        </div> -->

                                        <v-btn
                                            @click="onSubmit"
                                            block
                                            color="primary"
                                            type="submit"
                                            class="mt-6"
                                            :loading="isLoading">
                                            {{ $t('Login.Login') }}
                                        </v-btn>
                                    </v-form>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <v-snackbar :timeout="2000" top v-model="loginError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorLogin') }}</p>
        </v-snackbar>
    </div>
</template>

<script>
    // eslint-disable-next-line object-curly-newline
    import { mdiFacebook, mdiTwitter, mdiGithub, mdiGoogle, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';
    import { ref } from '@vue/composition-api';
    import themeConfig from '@themeConfig';
    import { login } from '@/api/auth';
    import auth from '@/mixins/auth.js';

    export default {
        mixins: [auth],
        data() {
            return {
                isLoading: false,
                form: {
                    identifier: '',
                    password: '',
                },
                loginError: false,
            };
        },
        methods: {
            onSubmit() {
                this.isLoading = true;
                login(this.form)
                    .then((response) => {
                        console.log(response.data);
                        this.onLoginSuccess(response.data);
                        this.isLoading = false;
                        console.log(this.$store.getters['auth/getUser']);
                        this.$router.push({
                            name: 'EntityList',
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.isLoading = false;
                        this.loginError = true;
                    });
            },
        },
        setup() {
            const isPasswordVisible = ref(false);

            const email = ref('');
            const password = ref('');

            const socialLink = [
                {
                    icon: mdiFacebook,
                    color: '#4267b2',
                    colorInDark: '#4267b2',
                },
                {
                    icon: mdiTwitter,
                    color: '#1da1f2',
                    colorInDark: '#1da1f2',
                },
                {
                    icon: mdiGithub,
                    color: '#272727',
                    colorInDark: '#fff',
                },
                {
                    icon: mdiGoogle,
                    color: '#db4437',
                    colorInDark: '#db4437',
                },
            ];

            return {
                isPasswordVisible,
                email,
                password,
                socialLink,

                // Icons
                icons: {
                    mdiEyeOutline,
                    mdiEyeOffOutline,
                },

                // themeConfig
                appName: themeConfig.app.name,
                appLogo: themeConfig.app.logo,
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import '@core/preset/preset/pages/auth.scss';
</style>
